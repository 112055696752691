/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Charts settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepLabel,
	Stepper
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import lod_ from "lodash";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import ChartsActions from "redux-react/actions/chartsActions";
import { display } from "redux-react/reducers/snackBarReducer";
import { useSelector, useDispatch } from "react-redux";
import { createCode } from "../filters";
import ChartChoiceStep from "./steps/1. ChartChoiceStep";
import SubTypeChoiceStep from "./steps/2. SubTypeChoiceStep";
import DataChoiceStep from "./steps/3. DataChoiceStep";
import DisplayChoiceStep from "./steps/4. DisplayChoiceStep";

export default function AddChartDialog({
	open,
	handleCloseDialog,
	handleSave,
	collections: propsCollections = [],
	edit = false,
	chart: propsChart = {},
	step = 0,
	code = null
}) {
	const assistantID = useSelector(state => selectCurrentProfile(state).assistantID);
	const profile = useSelector(state => selectCurrentProfile(state));
	const topContainer = useRef(null);
	const dispatch = useDispatch();

	const [collections, setCollections] = useState(propsCollections);
	const [loading, setLoading] = useState(true);

	function getCorrectChart() {
		if (edit) {
			return propsChart;
		} else {
			return {
				documentType: "chart",
				assistantID,
				code: createCode(assistantID),
				display: {
					icon: {
						component: "equalizer",
						color: "info"
					},
					pages: []
				},
				request: {},
				filters: {},
				options: {
					legend: true
				},
				active: true
			};
		}
	}
	// Chart skeleton
	const [newChart, setNewChart] = useState({});
	/**
	 * Active step
	 */
	const [activeStep, setActiveStep] = useState(0);
	/**
	 * Steps labels
	 */
	const [steps, setSteps] = useState([
		i18n.t("SETTINGS.CHARTS.NEW.STEPS.1"),
		i18n.t("SETTINGS.CHARTS.NEW.STEPS.2"),
		i18n.t("SETTINGS.CHARTS.NEW.STEPS.3"),
		i18n.t("SETTINGS.CHARTS.NEW.STEPS.4")
	]);
	/**
	 * Can user go to next step
	 */
	const [stepValid, setStepValid] = useState(false);
	/**
	 * Is last step
	 */
	const isLastStep = activeStep === steps.length - 1;
	/**
	 * Close modale
	 */
	function close(e, reason) {
		handleCloseDialog();
		/**
		 * On component close, reset all states
		 * Timeout to wait for animation end
		 */
		setTimeout(() => {
			setNewChart({
				documentType: "chart",
				assistantID,
				code: createCode(assistantID),
				display: {
					icon: {
						component: "equalizer",
						color: "info"
					},
					pages: []
				},
				request: {},
				filters: {},
				options: {
					legend: true
				},
				active: true
			});
			setStepValid(false);
			setActiveStep(0);
			setSteps([
				i18n.t("SETTINGS.CHARTS.NEW.STEPS.1"),
				i18n.t("SETTINGS.CHARTS.NEW.STEPS.2"),
				i18n.t("SETTINGS.CHARTS.NEW.STEPS.3"),
				i18n.t("SETTINGS.CHARTS.NEW.STEPS.4")
			]);
		}, 400);
	}
	/**
	 * Submit chart
	 */
	function sumbit() {
		let clonedChart = lod_.cloneDeep(newChart);
		delete clonedChart.editMode;
		handleSave({ chart: clonedChart, edit: edit });
		close();
	}
	/**
	 * User select chart type
	 */
	const handleSelectChart = chart => {
		setNewChart(c => {
			c.request = {};
			c.filters = {};
			c.options = {
				legend: true
			};
			c.type = chart.type;
			return c;
		});

		setSteps([
			`${i18n.t("SETTINGS.CHARTS.NEW.STEPS.1")} (${i18n.t(chart.title)})`,
			...steps.slice(1, steps.length)
		]);
	};
	/**
	 * User select chart subType
	 */
	const handleSelectSubType = subType => {
		setNewChart(chart => {
			delete chart.display.extra;

			chart.request = {};
			chart.filters = {};
			chart.options = {
				legend: true
			};
			chart.display.subType = subType.code;

			if (subType.code === "sortedList") {
				chart.request.other = false;
				chart.request.limit = 5;
				chart.request.sort = "desc";
				chart.request.attributesDisplay = [];
			}

			if (subType.code === "ranking") {
				chart.display.extra = {
					global: {
						active: true
					},
					max: {
						active: true
					},
					min: {
						active: true
					}
				};
			}

			return chart;
		});

		setSteps([
			steps[0],
			`${i18n.t("SETTINGS.CHARTS.NEW.STEPS.2")} (${i18n.t(subType.title)})`,
			...steps.slice(2, steps.length)
		]);
	};
	/**
	 * User edit chart's request
	 */
	const handleSubmitDatas = request => {
		setNewChart(chart => {
			chart.request = request;
			return chart;
		});
	};
	/**
	 * User edit chart's display
	 */
	const handleSubmitDisplay = display => {
		setNewChart(chart => {
			chart.display = display;
			return chart;
		});
	};
	/**
	 * User edit chart's filters
	 */
	const handleSubmitFilters = filters => {
		setNewChart(chart => {
			chart.filters = filters;
			return chart;
		});
	};
	/**
	 * User edit chart's options
	 */
	const handleSubmitOptions = options => {
		setNewChart(chart => {
			chart.options = options;
			return chart;
		});
	};
	/**
	 * User can go to next step
	 */
	const validStep = (val = true) => {
		setStepValid(val);
	};
	/**
	 * Go to next step
	 */
	const handleNext = () => {
		setActiveStep(activeStep + 1);
		setStepValid(false);
		topContainer?.current?.scrollIntoView();
	};
	/**
	 * Go to previous step
	 */
	const handleBack = () => {
		setActiveStep(activeStep - 1);
		topContainer?.current?.scrollIntoView();
	};
	/**
	 * Get actual step content
	 */
	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return (
					<ChartChoiceStep
						chart={newChart}
						handleSelectChart={handleSelectChart}
						validStep={validStep}
						handleNext={handleNext}
					/>
				);
			case 1:
				return (
					<SubTypeChoiceStep
						chart={newChart}
						handleSelectSubType={handleSelectSubType}
						validStep={validStep}
						handleNext={handleNext}
					/>
				);
			case 2:
				return (
					<DataChoiceStep
						chart={newChart}
						handleSubmitDatas={handleSubmitDatas}
						collections={collections}
						validStep={validStep}
						isStepValid={stepValid}
						handleSubmitFilters={handleSubmitFilters}
						handleSubmitOptions={handleSubmitOptions}
						handleSubmitDisplay={handleSubmitDisplay}
					/>
				);
			case 3:
				return (
					<DisplayChoiceStep
						chart={{ ...newChart, editMode: true }}
						setChart={setNewChart}
						validStep={validStep}
						edit={edit}
					/>
				);
			default:
				return null;
		}
	}

	function getActionButtonLabel() {
		if (isLastStep) {
			if (edit) {
				return i18n.t("SETTINGS.edit");
			} else {
				return i18n.t("SETTINGS.add");
			}
		} else {
			return i18n.t("SETTINGS.CHARTS.NEW.STEPS.next");
		}
	}

	useEffect(() => {
		if (assistantID) {
			setLoading(true);
			setActiveStep(step);
			if (!code) {
				setLoading(false);
				setNewChart(getCorrectChart());
			} else {
				const onSuccess = res => {
					if (res.chart) {
						setLoading(false);
						setCollections(res.chart.collections);
						setNewChart(res.chart);
					} else {
						dispatch(
							display({
								message: i18n.t("SETTINGS.CHARTS.ERROR.notFound"),
								type: "error"
							})
						);
					}
				};
				dispatch(ChartsActions.getChartByCode(profile.assistantID, code, onSuccess));
			}
		}
	}, [open, code]);

	if (loading) {
		return null;
	}

	/**
	 * Main component
	 */
	return (
		<Dialog
			fullWidth
			maxWidth="xxxl"
			PaperProps={{
				sx: {
					height: "95%"
				}
			}}
			open={open}
			onClose={close}
		>
			<DialogTitle>
				<MDBox>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</MDBox>
			</DialogTitle>
			<DialogContent>
				<div data-id="top-container" ref={topContainer}></div>
				<MDBox
					style={{
						height: "100%"
					}}
				>
					{getStepContent(activeStep)}
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton
					disabled={activeStep === 0}
					variant="contained"
					color="light"
					onClick={handleBack}
				>
					{i18n.t("SETTINGS.CHARTS.NEW.STEPS.back")}
				</MDButton>
				<MDButton
					disabled={!stepValid}
					variant="contained"
					color="info"
					onClick={!isLastStep ? handleNext : sumbit}
				>
					{getActionButtonLabel()}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}
